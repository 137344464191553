$(document).ready(function() {

    var breakpoints = {};

    breakpoints[Astro.breakpoints.tablet.size] = {
        slidesPerView: 4
    }

    breakpoints[Astro.breakpoints.mobile.size] = {
        slidesPerView: 2
    }

    var awardsArgs = {
        nextButton: '.js-awards__next',
        prevButton: '.js-awards__previous',
        slidesPerView: 6,
        autoplay: 2500,
        autoplayDisableOnInteraction: false,
        loop: true,
        breakpoints: breakpoints
    };

    var mySwiper = new Swiper('.awards__container', awardsArgs);
});
